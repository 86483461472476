import dayjs from 'dayjs';

export function getTime(time) {
  const [start, end] = time.split('-');
  return { start, end };
}

export function getDateFromTime(time) {
  const [hour, minute] = time.split(':');
  return dayjs().hour(hour).minute(minute);
}

export function getRange(periods) {
  const times = periods.map((period) => {
    const [start, end] = getTime(period);

    return {
      start: getDateFromTime(start),
      end: getDateFromTime(end),
    };
  });

  const start = times
    .sort((a, b) => a.start.isAfter(b.start));

  const end = times
    .sort((a, b) => a.start.isAfter(b.start));

  return { start, end };
}

export function createTimeSlot(start, end, duration = 15, gap = 0) {
  const startTime = getDateFromTime(start);
  const endTime = getDateFromTime(end);

  const diff = startTime.diff(endTime, 'hour');
  const maxSlotCount = Math.ceil(Math.abs(diff) / (duration / 60));

  let time = startTime;
  const slots = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i <= maxSlotCount; i++) {
    if (time.isBefore(endTime)) {
      slots.push(time.format('HH:mm'));

      time = time
        .add(duration, 'minute')
        .add(gap, 'minute');
    }
  }

  return slots;
}

export function millisToMinutesAndSeconds(millis) {
  const minutes = Math.floor(millis / 60000);
  const seconds = ((millis % 60000) / 1000).toFixed(0);
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
}

export function getDurationForReservation(reservation) {
  const date = reservation.first_join ? reservation.consultant_joined_at : reservation.joined_at;
  const startTime = dayjs(date);
  const endTime = reservation.consultant_disconnected_at ? dayjs(reservation.consultant_disconnected_at) : null;

  if (!reservation || !startTime || endTime === null) {
    return '0:00';
  }

  const diff = endTime.diff(startTime, 'millisecond');
  return millisToMinutesAndSeconds(diff);
}

<template>
  <vertical-modal>
    <div class="flex flex-col py-20 px-10 text-opacity-70 text--black">
      <div class="flex flex-col">
        <div class="flex flex-col items-center py-5 border-b-2 border-salmon border-opacity-30 w-full">
          <avatar class="w-20 h-20 mb-3" :name="item.name" :image="item.avatar" />
          <strong class="text-xl text-blue font-medium">{{ item.name }}</strong>
        </div>

        <div class="grid grid-cols-2 py-5 border-b-2 border-salmon border-opacity-30 w-full">
          <div class="text-black text-opacity-70">E-mail</div>
          <div class="col-start-2 col-end-3 text-black">{{ item.email }}</div>
        </div>

        <div class="grid grid-cols-2 py-5 border-b-2 border-salmon border-opacity-30 w-full">
          <div class="text-black text-opacity-70">DDD Celular</div>
          <div class="col-start-2 col-end-3 text-black">{{ item.phone }}</div>
        </div>

        <div class="grid grid-cols-1 py-5 w-full">
          <div class="text-black text-opacity-70 mb-2">Mini bio</div>
          <div class="text-black italic text-opacity-70">
            {{ item.welcome_message }}
          </div>
        </div>

        <div class="grid grid-cols-2 py-5 border-b-2 border-salmon border-opacity-30 w-full">
          <div class="text-black text-opacity-70">Consultas agendadas</div>
          <div class="col-start-2 col-end-3 text-black">{{ item.total_scheduled_reservations }}</div>
        </div>

        <div class="grid grid-cols-2 py-5 border-b-2 border-salmon border-opacity-30 w-full">
          <div class="text-black text-opacity-70">Consultas realizadas</div>
          <div class="col-start-2 col-end-3 text-black">{{ item.total_perfomed_reservations }}</div>
        </div>

        <div class="grid grid-cols-2 border-b-2 border-salmon border-opacity-30 py-5 w-full">
          <div class="text-black text-opacity-70">Avaliação média</div>
          <div class="col-start-2 col-end-3">
            <rating :value="item.average_rating" />
            <span class="text-sm opacity-60" v-if="item.total_ratings">{{ item.total_ratings }} avaliações</span>
          </div>
        </div>

        <div class="grid grid-cols-2 border-b-2 border-salmon border-opacity-30 py-5 w-full">
          <div class="text-black text-opacity-70">Tempo médio das consultas</div>
          <div class="col-start-2 col-end-3 text-black">{{ item.average_duration > 0 ? item.average_duration.toFixed() : 0 }}</div>
        </div>

        <div class="grid grid-cols-2 border-b-2 border-salmon border-opacity-30 py-5 w-full">
          <div class="text-black text-opacity-70">Duração das consultas</div>
          <div class="col-start-2 col-end-3 text-black">{{ item.duration }} minutos</div>
        </div>

        <div class="grid grid-cols-2 border-b-2 border-salmon border-opacity-30 py-5 w-full">
          <div class="text-black text-opacity-70">Intervalo entre consultas</div>
          <div class="col-start-2 col-end-3 text-black">{{ item.gap }} minutos</div>
        </div>

        <div class="grid grid-cols-1 py-5 w-full">
          <div class="grid grid-cols-2 mb-5">
            <span class="text-black text-opacity-70 items-center">Horário disponíveis</span>
            <v-text-field v-model="timesearch" label="buscar" class="mt-0 pt-0" hide-details="auto" /> {{timesearch}}
          </div>
          <div class="grid grid-cols-7 text-sm text-black max-h-80 overflow-y-auto">
            <div class="flex flex-col" v-for="(day) in days" :key="day.key">
              <h3 class="opacity-75 font-medium mb-4">{{ day.name.slice(0, 3) }}</h3>

              <ul class="font-normal text-black text-opacity-70 space-y-3" v-for="period in day.periods" :key="day.key + period">
                <li v-for="time in period" :key="day.key + time" :class="{
                  'hidden': timesearch != '' && timesearch != time,
                  'block': timesearch != '' && timesearch == time,
                }">
                  {{ time }}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-2 border-b-2 border-salmon border-opacity-30 py-5 w-full">
          <div class="text-black text-opacity-70">Total de horários <br> disponíveis por semana</div>
          <div class="col-start-2 col-end-3 text-black">{{ totalSlots }}</div>
        </div>
      </div>

      <div class="flex justify-between mt-12">
        <Button ghost light :to="{ name: 'RegisterConsultant', params: { action: 'edit', id: item.id } }">Atualizar</Button>

        <Button ghost light @click="openDeleteRegisterModal">
          <svg width="16" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 7v10H4V7h8zm-1.5-6h-5l-1 1H1v2h14V2h-3.5l-1-1zM14 5H2v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V5z" fill="#00136F" stroke="#fff" stroke-width=".5"/></svg>
        </Button>
      </div>
    </div>
  </vertical-modal>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

import Button from '@/components/Button';
import Rating from '@/components/Rating';
import Avatar from '@/components/Avatar';
import VerticalModal from '@/components/VerticalModal';
import { createTimeSlot, getTime } from '@/utils/schedule.js';

export default {
  name: "AdminUser",
  components: {
    Avatar,
    Rating,
    Button,
    VerticalModal,
  },
  props: ['item'],
  computed: {
    ...mapState('verticalModal', ['open']),

    totalSlots() {
      return this.days
        .map((d) => d.periods.flat().length)
        .reduce((a, b) => a + b, 0);
    }
  },
  watch: {
    item: {
      deep: true,
      handler() {
        this.populateDays();
      }
    }
  },
  data() {
    return {
      timesearch: '',
      days: [
        {
          key: 'monday',
          name: 'Segunda',
          periods: []
        },
        {
          key: 'tuesday',
          name: 'Terça',
          periods: []
        },
        {
          key: 'wednesday',
          name: 'Quarta',
          periods: []
        },
        {
          key: 'thursday',
          name: 'Quinta',
          periods: []
        },
        {
          key: 'friday',
          name: 'Sexta',
          periods: []
        },
        {
          key: 'saturday',
          name: 'Sábado',
          periods: []
        },
        {
          key: 'sunday',
          name: 'Domingo',
          periods: []
        },
      ],
    }
  },
  methods: {
    ...mapMutations(['openDeleteRegisterModal']),
    populateDays() {
      const weekDays = Object.keys(this.item.availability);

      weekDays.forEach((key) => {
        const periods = this.item.availability[key]
          .map((period) => {
            return getTime(period);
          })
          .map((period) => {
            return this.createTimeSlots(period);
          });

        const day = this.days.find((d) => d.key === key);
        day.periods = periods;
      });
    },
    createTimeSlots(period) {
      return createTimeSlot(period.start, period.end, this.item.duration, this.item.gap);
    }
  },
};
</script>

<style lang="scss"></style>

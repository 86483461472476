<template>
  <div class="consultants admin-page p-12 ml-auto">
    <div class="container">
      <header class="admin-page-header grid grid-cols-3 mb-10 items-center">
        <h1 class="text-3xl text-blue">Consultoras</h1>
        <search class="mx-auto" v-model="search" />
        <div class="flex justify-end items-end">
          <Button @click="confirmationModal = 'message'" :class="{'mr-10 opacity-0 pointer-events-none': true, 'opacity-100 pointer-events-auto': selected.length}" ghost>Excluir selecionados</Button>
          <Button :to="{ name: 'RegisterConsultant', params: {action:'add'} }">Cadastrar</Button>
        </div>
      </header>

      <div class="consultants-table relative admin-data-table bg-white rounded-lg pb-12">
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="items"
          :loading="loading"
          loading-text="Carregando... Aguarde"
          item-key="id"
          show-select
          hide-default-footer
          disable-pagination
          class="elevation-1"
        >
          <template v-slot:item.name="{ item }">
            <div class="flex items-center" @click="openPreview(item)">
              <avatar class="menu-user-avatar w-12 h-12 mr-4 font-medium" :image="item.avatar" :name="item.name" />

              <div>
                {{ item.name }}
                <span class="block text-xs opacity-60">{{ item.email }}</span>
              </div>
            </div>
          </template>

          <template v-slot:item.rating="{ item }">
            <div class="relative tooltipContainer" @mouseenter="showTooltip(item, $event)" @mouseleave="hideTooltip">
              <rating :value="item.average_rating" />
              <span class="text-xs opacity-60" v-if="item.total_ratings">{{item.total_ratings}} avaliações</span>
            </div>
          </template>

          <template v-slot:item.actions="{ item }">
            <div class="table-actions">
              <router-link :to="{ name: 'RegisterConsultant', params: { action: 'edit', id: item.id } }" class="consultants-table__actions consultants-table__actions-edit w-8 h-8 mr-4"></router-link>
              <button @click="remove(item)" class="consultants-table__actions consultants-table__actions-trash w-8 h-8"></button>
            </div>
          </template>
        </v-data-table>

        <rating-tooltip :item="selectedItem" :hidden="tooltipData.hidden" :style="`left: ${tooltipData.x}px; top: ${tooltipData.y}px;`" />
        <pagination :paginator="paginator" @load="loadItems" />
        <delete-register-modal :item="selectedItem" model="consultants" @done="loadItems" />

      </div>
    </div>

    <modal-consultant :item="selectedItem" />
    <modal-confirmation 
      :state="confirmationModal" 
      cancelbutton="Não" 
      confirmbutton="Sim" 
      :title="`Deseja realmente excluir ${selected.length} ${selected.length > 1 ? 'itens' : 'item'}?`" 
      v-on:confirm="deleteSelecteds"
      v-on:cancel="cancelDeletetion" />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

import api from '@/utils/api.js';
import paginator from '@/utils/paginator.js';

import Search from '@/components/admin/Search';
import Pagination from '@/components/Pagination';
import Button from "@/components/Button";
import Avatar from '@/components/Avatar';
import Rating from '@/components/Rating';
import RatingTooltip from '@/components/RatingTooltip';
import ModalConsultant from "@/components/modals/Consultant";
import ModalConfirmation from "@/components/modals/Confirmation";
import DeleteRegisterModal from '@/components/modals/DeleteRegisterModal';

export default {
  name: "Consultants",
  components: {
    Avatar,
    Button,
    Search,
    Pagination,
    Rating,
    RatingTooltip,
    ModalConsultant,
    ModalConfirmation,
    DeleteRegisterModal
  },
  data() {
    return {
      loading: true,
      confirmationModal: 'close',
      search: '',
      selected: [],
      tooltipData: {
        x: 0,
        y: 0,
        hidden: true,
      },
      headers: [
        {
          text: 'Nome',
          align: 'start',
          value: 'name',
        },
        {
          text: 'DDD Celular',
          value: 'phone'
        },
        {
          text: 'Avaliação',
          value: 'rating'
        },
        {
          text: '',
          value: 'actions',
          sortable: false
        },
      ],
      items: [],
      paginator: null,
      selectedItem: {}
    }
  },
  watch: {
    search() {
      this.loadItems();
    }
  },
  mounted() {
    this.loadItems();
  },
  methods: {
    ...mapMutations(['openDeleteRegisterModal']),

    async loadItems(meta) {
      this.loading = true;
      this.paginator = await paginator('consultants', meta, this.search);
      this.items = this.paginator.data.map((item, index) => {
        return {
          ...item,
          index
        }
      });
      this.loading = false;
    },

    openPreview(item) {
      this.selectedItem = item;
      this.$store.dispatch('verticalModal/openModal');
    },

    remove(item) {
      this.selectedItem = item;
      this.openDeleteRegisterModal();
    },
    showTooltip(item, event) {
      const $container = (event.path[0]) ? event.path[0] : false;

      if (!$container) {
        return;
      }

      const {x, y, height} = $container.getBoundingClientRect();

      this.tooltipData = {
        x: x - 70,
        y: y + height + 15,
        hidden: false
      };

      this.selectedItem = item;
    },

    async deleteSelecteds() {
      this.confirmationModal = 'loading';

      const deletions = this.selected.map(async (item) => {
        await api.delete('/consultants/'+ item.id);
      });

      try {
        await Promise.all(deletions);
      } catch(error) {
        console.log(error);
      }

      this.confirmationModal = 'close';      
      this.loadItems();
    },

    cancelDeletetion() {
      this.confirmationModal = 'close';
      this.selected = [];
    },

    hideTooltip() {
      this.tooltipData = {
        x: this.tooltipData.x, 
        y: this.tooltipData.y, 
        hidden: true
      }
    },
  }
};
</script>

<style lang="scss">
.consultants-table {
  .v-data-table-header {
    th.text-start {
      &:last-child {
        span {
          background-image: none;
        }
      }
    }
  }

  tbody {
    td.text-start {
      position: relative;
      height: 74px!important;
    }
  }
}
.consultants-table__actions {
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: 50%;

  &:focus {
    outline: none;
  }
}

.consultants-table__actions-edit {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 15.25V19h3.75L15.81 7.94l-3.75-3.75L1 15.25zM3.92 17H3v-.92l9.06-9.06.92.92L3.92 17zM18.71 3.63l-2.34-2.34c-.2-.2-.45-.29-.71-.29-.26 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83a.996.996 0 000-1.41z' fill='%2300136F' stroke='%23fff' stroke-width='.5'/%3E%3C/svg%3E");
}

.consultants-table__actions-trash {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 7v10H4V7h8zm-1.5-6h-5l-1 1H1v2h14V2h-3.5l-1-1zM14 5H2v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V5z' fill='%2300136F' stroke='%23fff' stroke-width='.5'/%3E%3C/svg%3E");
}
</style>

<template>
  <div :class="{
    'rating-tooltip fixed bg-salmon py-4 px-6 rounded-md text-white z-indez-10': true,
    'opacity-0 pointer-events-none': hidden,
    'transition-opacity': !hidden
    }"
    v-if="item.rating_data"
  >
    <h3 class="rating-tooltip__resume font-medium text-xl mb-3">{{ item.average_rating == null ? 0 : item.average_rating }} de 5</h3>

    <ul class="rating-tooltip-list">
      <li class="rating-tooltip-item">
        <div class="flex items-center justify-between">
          <h4 class="rating-tooltip-stars text-sm mr-4 flex-shrink-0">5 estrelas</h4>
          
          <span class="rating-tooltip-item__progress-bar block">
            <span class="bg-blue h-full" :style="`width: ${item.rating_data[5]}%;`"></span>
          </span>
          
          <h4 class="text-sm ml-4 w-16 text-right">{{item.rating_data[5]}}%</h4>
        </div>
      </li>

      <li class="rating-tooltip-item">
        <div class="flex items-center justify-between">
          <h4 class="rating-tooltip-stars text-sm mr-4 flex-shrink-0">4 estrelas</h4>

          <span class="rating-tooltip-item__progress-bar block">
            <span class="bg-blue h-full" :style="`width: ${item.rating_data[4]}%;`"></span>
          </span>
          
          <h4 class="text-sm ml-4 w-16 text-right">{{item.rating_data[4]}}%</h4>
        </div>
      </li>

      <li class="rating-tooltip-item">
        <div class="flex items-center justify-between">
          <h4 class="rating-tooltip-stars text-sm mr-4 flex-shrink-0">3 estrelas</h4>

          <span class="rating-tooltip-item__progress-bar block">
            <span class="bg-blue h-full" :style="`width: ${item.rating_data[3]}%;`"></span>
          </span>
          
          <h4 class="text-sm ml-4 w-16 text-right">{{item.rating_data[3]}}%</h4>
        </div>
      </li>

      <li class="rating-tooltip-item">
        <div class="flex items-center justify-between">
          <h4 class="rating-tooltip-stars text-sm mr-4 flex-shrink-0">2 estrelas</h4>

          <span class="rating-tooltip-item__progress-bar block">
            <span class="bg-blue h-full" :style="`width: ${item.rating_data[2]}%;`"></span>
          </span>
          
          <h4 class="text-sm ml-4 w-16 text-right">{{item.rating_data[2]}}%</h4>
        </div>
      </li>

      <li class="rating-tooltip-item">
        <div class="flex items-center justify-between">
          <h4 class="rating-tooltip-stars text-sm mr-4 flex-shrink-0">1 estrela</h4>

          <span class="rating-tooltip-item__progress-bar block">
            <span class="bg-blue h-full" :style="`width: ${item.rating_data[1]}%;`"></span>
          </span>
          
          <h4 class="text-sm ml-4 w-16 text-right">{{item.rating_data[1]}}%</h4>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'RatingTooltip',
  props: {
    item: {},
    'hidden': {
      default: true
    }
  },
}
</script>

<style lang="scss">
.rating-tooltip {
  width: 315px;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    top: -12px;
    left: 65px;

    width: 0; 
    height: 0; 
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    
    border-bottom: 20px solid theme('colors.salmon');
  }
}

.rating-tooltip-item {
  & + & {
    margin-top: 10px;
  }
}

.rating-tooltip-stars {
  width: 70px;
}

.rating-tooltip-item__progress-bar {
  width: 100%;
  height: 4px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.5);

  span {
    display: block;
    height: 100%;
    border-radius: 5px;
  }
}
</style>

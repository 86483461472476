<template>
  <div
    class="vertical-modal fixed top-0 left-0 w-screen h-screen flex justify-end z-indez-10 transition duration-300 ease-in-out"
    :class="{
      'pointer-events-none opacity-0': !open
    }"
  >
    <div class="absolute top-0 left-0 w-screen h-screen z-indez-20 bg-black bg-opacity-50" @click="closeModal"></div>

    <div
      class="relative vertical-modal__content z-indez-30 h-screen overflow-y-auto bg-white transform transition duration-300 ease-in-out"
      :class="{
        'pointer-events-none opacity-0 translate-x-full': !open
      }"
    >
      <slot />
    </div>

    <span class="absolute top-10 right-10 z-index-20 cursor-pointer" @click="closeModal">
      <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path fill="#00136F" d="M15.0813 0.331945C14.6387 -0.110649 13.9212 -0.110648 13.4786 0.331946L7.70666 6.10384L1.93485 0.332041C1.49226 -0.110554 0.774669 -0.110554 0.332074 0.33204C-0.110521 0.774635 -0.110521 1.49222 0.332075 1.93482L6.10388 7.70661L0.331946 13.4785C-0.110649 13.9211 -0.110649 14.6387 0.331946 15.0813C0.774541 15.5239 1.49213 15.5239 1.93473 15.0813L7.70666 9.30939L13.4787 15.0814C13.9213 15.524 14.6389 15.524 15.0815 15.0814C15.5241 14.6388 15.5241 13.9212 15.0815 13.4786L9.30944 7.70661L15.0813 1.93472C15.5239 1.49213 15.5239 0.77454 15.0813 0.331945Z" />
      </svg>
    </span>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: "VerticalModal",
  props: ['beforeclose'],
  computed: {
    ...mapState('verticalModal', ['open']),
  },
  methods: {
    ...mapActions('verticalModal', ['closeModal']),
  }
};
</script>

<style lang="scss">
  .vertical-modal__content {
    width: 40rem;
  }
</style>

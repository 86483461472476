<template>
  <div class="rating flex items-center" v-if="hasRating">
    <svg v-for="star in stars" :key="star" width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.9797 6.04549C16.8676 5.70742 16.5618 5.46806 16.2015 5.43609L11.2872 4.99853L9.34496 0.539087C9.20156 0.211457 8.87529 0 8.51212 0C8.14896 0 7.82256 0.211457 7.68007 0.539087L5.73788 4.99853L0.822712 5.43609C0.462406 5.4687 0.157303 5.70805 0.0445614 6.04549C-0.0675307 6.38357 0.0359888 6.75438 0.308491 6.98877L4.02338 10.1834L2.92804 14.9147C2.8479 15.2626 2.98558 15.6223 3.27991 15.831C3.43811 15.9437 3.62397 16 3.81049 16C3.97077 16 4.13118 15.9582 4.27445 15.8741L8.51212 13.3891L12.749 15.8741C13.0598 16.0563 13.4507 16.0396 13.7443 15.831C14.0387 15.6223 14.1763 15.2626 14.0962 14.9147L13.0009 10.1834L16.7158 6.98877C16.9881 6.75438 17.0918 6.38433 16.9797 6.04549Z"
        :fill="star <= value ? '#F69B8B' : '#fff'"
        stroke-width="1px"
        stroke="#F69B8B"
      />
    </svg>
  </div>
  <p v-else>Não avaliado</p>
</template>

<script>
export default {
  name: 'Rating',
  props: ['value'],
  data() {
    return {
      stars: [1, 2, 3, 4, 5]
    }
  },
  computed: {
    hasRating() {
      return Number.isInteger(this.value);
    }
  }
}
</script>

<style lang="scss">

</style>
